<template>
	<div>
		<v-data-table
			:headers="headers"
			:items="branches"
			:expanded.sync="expanded"
			sort-by="brnc_code"
			class="elevation-1"
			dense
			:search="search"
			show-expand
		>
			<template v-slot:top>
				<v-toolbar flat color="white">
						<v-toolbar-title>Branch File Maintenance</v-toolbar-title>
						<v-spacer></v-spacer>
						<v-text-field v-model="search" append-icon="search" label="Search" single-line hide-details></v-text-field>
						<vue-excel-xlsx
							:data="branches"
							:columns="columnHeaders"
							:file-name="module_name"
							>
							<v-btn icon alt class="mt-2" :color="branches.length=== 0 ? 'gray' : 'primary'" :disabled="branches.length===0"><v-icon>mdi-arrow-down-bold-circle</v-icon> </v-btn>
						</vue-excel-xlsx>
				</v-toolbar>
			</template>
			<template v-slot:expanded-item="{ item }">
				<td :colspan="headers.length">
					<v-simple-table>
						<template v-slot:default>
							<thead v-if="item.payee_banks.length != 0">
								<tr>
									<th>Payee Banks</th>
									<th>Action</th>
								</tr>
							</thead>
							<tbody>
								<tr v-for="payee_bank in item.payee_banks" :key="payee_bank.payee_account_id">
									<td>{{payee_bank.item_data}}</td>
									<td>
										<v-icon color="primary" small class="mr-2" @click="editPayeeBank(item, payee_bank)">edit</v-icon>
										<v-icon color="red" small @click="deletePayeeBank(item, payee_bank)">delete</v-icon>
									</td>
								</tr>
								<tr v-if="item.payee_banks.length == 0">No Payee Bank for this Payee</tr>
								<tr>
									<v-btn outlined color="primary" @click="addPayeeBank(item)"><v-icon dark>mdi-plus</v-icon>Add Payee Bank</v-btn>
								</tr>
							</tbody>
						</template>
					</v-simple-table>
				</td>
			</template>
			<template v-slot:item.action="{ item }">
					<v-icon
					color="grey"
					class="mr-2"
					small
					v-if="role_access < 3"
				>
					mdi-pencil-off
				</v-icon>
				<v-icon
					color="primary"
					v-else
					class="mr-2"
					small
					@click="editItem(item)"
				>
					edit
				</v-icon>
					<v-icon
					color="grey"
					class="mr-2"
					small
					v-if="role_access < 4"
				>
					mdi-delete-off
				</v-icon>
				<v-icon
					small
					v-else
					color="red"
					@click="deleteItem(item)"
				>
					delete
				</v-icon>
			</template>
			<!-- <template v-slot:body.append>
					<tr>
						<td colspan="8">
							<v-layout>
								<v-spacer></v-spacer>
								<v-flex md1>
									<v-layout justify-center>
										<v-btn
											:disabled="parseInt(branches.current_page)==1"
											@click="changePrevData"
											class="ma-2"
											text
											icon
										>
											<v-icon :disabled="parseInt(branches.current_page)==1">mdi-chevron-left</v-icon>
										</v-btn>
									</v-layout>
								</v-flex>
								<v-flex md2 style="padding-top:15px;text-align: center">
									<p class="font-weight-light text--primary body-2">
										Page {{branches.current_page}} ( {{branches.from}} - {{branches.to}} )
									</p>
								</v-flex>
								<v-flex md1>
									<v-layout justify-center>
										<v-btn
											@click="changeNextData"
											class="ma-2"
											:disabled="!branches.next_page_url"
											text
											icon
										>
											<v-icon :disabled="!branches.next_page_url">mdi-chevron-right</v-icon>
										</v-btn>
									</v-layout>
								</v-flex>
							</v-layout>
						</td>
					</tr>
			</template> -->
		</v-data-table>
		<create-branch v-if="role_access > 1"></create-branch>
	<create-payeebank :modules="'branch'" :id.sync="id"></create-payeebank>
	</div>
</template>

<script>
import createBranch from './createBranch.vue';
import createPayeeBank from '../payeebank/createPayeeBank.vue';
import { mapGetters } from 'vuex';

export default {
	components: {
		'create-branch': createBranch,
		'create-payeebank': createPayeeBank
	},
	data(){
		return{
			module_name: 'Branches',
			role_access: 1,
			id: 0,
			expanded: [],
			search: ''
		};
	},
	computed: {
		...mapGetters({
			branches: 'branch/branches',
			headers: 'branch/headers',
			columnHeaders: 'branch/columnHeaders',
			editedItem: 'branch/editedItem',
			defaultItem: 'branch/defaultItem',
			dialog: 'branch/dialog',
			editedIndex: 'branch/editedIndex',
			currUser: 'auth/currUser'
		}),
	},
	mounted () {
		this.$store.dispatch('branch/getBranches');

		let roles = this.currUser.roleaccess;
		if(roles.length > 0){
			let roleaccess = roles.filter(r => r.module_name === this.module_name);
			if(roleaccess.length > 0){
				this.role_access = roleaccess[0].access_level;
			}
		}
	},
	methods: {
		download() {
			//Download
		},
		async editItem (item) {
			await this.$store.dispatch('branch/getBranchDetails', item.id);
			this.$store.dispatch('branch/setEditedIndex',this.branches.indexOf(item));
			//this.$store.dispatch('branch/setEditedItems',Object.assign({}, item));
			this.$store.dispatch('branch/setDialog',true);
			this.$store.dispatch('branch/setValid',true);
			this.$store.dispatch('branch/setformTitle','Edit Entry');
		},
		deleteItem (item) {
			this.$swal.fire({
				title: 'Delete ' + item.brnc_name + '(' + item.brnc_code + ')' + '?',
				text: 'You won\'t be able to revert this!',
				type: 'warning',
				showCancelButton: true,
				confirmButtonColor: '#3085d6',
				cancelButtonColor: '#d33',
				confirmButtonText: 'Yes, delete it!'
			}).then((result) => {
				if (result.value) {
					this.$store.dispatch('branch/setEditedIndex', this.branches.indexOf(item));
					this.$store.dispatch('branch/deleteBranch',item);                              
				}
			});
		},
		addPayeeBank(item){
			let payload = {
				payee_account_id: item.id,
				payee_id: item.payee_id,
				bank_id: '',
				bank_branch: '',
				account_number: ''
			};
			this.$store.dispatch('branch/getBranchDetails', item.id);
			this.$store.dispatch('branch/setEditedIndex',this.branches.indexOf(item));
			//this.id = item.id;
			this.$store.commit('payefile/SET_EDITEDITEM_PAYEEBANK', payload, {root: true});
			this.$store.commit('payefile/SET_PAYEEBANK_DIALOG', true, {root: true});
			this.$store.dispatch('payefile/setformTitle','New Entry', { root: true});
		},
		editPayeeBank(item, payeebank){
			//this.id = item.id;
			this.$store.dispatch('branch/getBranchDetails', item.id);
			this.$store.dispatch('branch/setEditedIndex',this.branches.indexOf(item));
			// this.$store.dispatch('branch/getBranchDetails', payeebank.payee_id);
			this.$store.commit('payefile/SET_EDITEDITEM_PAYEEBANK', payeebank);
			this.$store.commit('payefile/SET_PAYEEBANK_DIALOG', true);
		},
		async deletePayeeBank(item, payeebank){
			await this.$store.dispatch('branch/getBranchDetails', item.id);
			this.$store.dispatch('branch/setEditedIndex',this.branches.indexOf(item));
			let deleted = [];
			deleted.push({payee_account_id: payeebank.payee_account_id});
			this.$swal.fire({
				title: 'Delete ' + payeebank.item_data + '?',
				text: 'You won\'t be able to revert this!',
				type: 'warning',
				showCancelButton: true,
				confirmButtonColor: '#3085d6',
				cancelButtonColor: '#d33',
				confirmButtonText: 'Yes, delete it!'
			}).then((result) => {
				if (result.value) {
					this.editedItem.edit_by = this.currUser.id;
					this.editedItem.updated = item.payee_banks;
					this.editedItem.deleted = deleted;
					this.editedItem.payee_banks = [];
					this.editedItem.updated = [];
					delete this.editedItem['paye_type'];
					delete this.editedItem['updated_at'];
					delete this.editedItem['created_at'];
					this.$store.dispatch('branch/updateBranch',this.editedItem);
				}
			});
		},
		changeNextData() {
			var nextPage = this.branches.current_page + 1;
			this.$store.dispatch('branch/getDataPages', nextPage);
		},

		changePrevData() {
			var prevPage = this.branches.current_page - 1;
			this.$store.dispatch('branch/getDataPages', prevPage);
		}
	}
};
</script>